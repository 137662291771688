
import { defineComponent, computed, watch } from "vue";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n-composable";
import { useRoute, useRouter } from "@/router";
export default defineComponent({
  name: "LocalLoader",

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    const componentName = computed(() => route.params.name);

    const { responseValues, otherValues, bookmarks } = storeToRefs(store);

    watch(responseValues, v => {
      localStorage.setItem(
        `${componentName.value}:responseValues`,
        JSON.stringify(v)
      );
    });
    watch(otherValues, v => {
      localStorage.setItem(
        `${componentName.value}:otherValues`,
        JSON.stringify(v)
      );
    });
    watch(bookmarks, v => {
      localStorage.setItem(
        `${componentName.value}:bookmarks`,
        JSON.stringify(v)
      );
    });

    store.setTemplateCompiled(true);
    router.replace({ query: {} }).catch(() => {});

    const localResponseValues = localStorage.getItem(
      `${componentName.value}:responseValues`
    );
    const localOtherValues = localStorage.getItem(
      `${componentName.value}:otherValues`
    );
    const localBookmarks = localStorage.getItem(
      `${componentName.value}:bookmarks`
    );

    if (localResponseValues) {
      store.setResponseValues(JSON.parse(localResponseValues));
    }

    if (localOtherValues) {
      store.setOtherValues(JSON.parse(localOtherValues));
    }

    if (localBookmarks) {
      store.setBookmarks(JSON.parse(localBookmarks));
    }

    i18n.locale.value = localStorage.getItem("locale") || "en";

    const component = () =>
      import(
        /* webpackInclude: /\.vue$/ */
        /* webpackChunkName: "testing" */
        /* webpackMode: "lazy-once" */
        `./templates/${componentName.value}.vue`
      );

    return {
      component
    };
  }
});
