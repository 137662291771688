
import { defineComponent, computed, watch } from "vue";
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "@/router";
import Assessment from "@/views/Assessment.vue";

export const generateHS256Token = async (
  secret: string,
  claims: any
): Promise<any> => {
  const strToUrlBase64 = (str: string) => {
    return binToUrlBase64(utf8ToBinaryString(str));
  };

  const utf8ToBinaryString = (str: string) => {
    const escstr = encodeURIComponent(str);
    // replaces any uri escape sequence, such as %0A,
    // with binary escape, such as 0x0A
    const binstr = escstr.replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    });

    return binstr;
  };

  const binToUrlBase64 = (bin: string) => {
    return btoa(bin).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+/g, "");
  };

  const uint8ToUrlBase64 = (uint8: any) => {
    let bin = "";
    uint8.forEach(function (code: any) {
      bin += String.fromCharCode(code);
    });
    return binToUrlBase64(bin);
  };

  const enc = new TextEncoder();
  const claimsUrlBase64 = strToUrlBase64(JSON.stringify(claims));
  const algorithm = { name: "HMAC", hash: "SHA-256" };
  return new Promise(resolve => {
    crypto.subtle
      .importKey("raw", enc.encode(secret), algorithm, false, [
        "sign",
        "verify"
      ])
      .then(v => {
        crypto.subtle
          .sign(
            algorithm.name,
            v,
            enc.encode(
              `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.${claimsUrlBase64}`
            )
          )
          .then(v => {
            const digest = uint8ToUrlBase64(new Uint8Array(v));
            resolve(
              `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.${claimsUrlBase64}.${digest}`
            );
          });
      });
  });
};

export default defineComponent({
  name: "RemoteLoader",

  components: {
    Assessment
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const key = computed(
      () => `${route.params.package}:${route.params.identifier}`
    );

    const { token, responseValues, otherValues, bookmarks } =
      storeToRefs(store);

    watch(responseValues, v => {
      localStorage.setItem(`${key.value}:responseValues`, JSON.stringify(v));
    });
    watch(otherValues, v => {
      localStorage.setItem(`${key.value}:otherValues`, JSON.stringify(v));
    });
    watch(bookmarks, v => {
      localStorage.setItem(`${key.value}:bookmarks`, JSON.stringify(v));
    });

    router.replace({ query: {} }).catch(() => {});

    const localResponseValues = localStorage.getItem(
      `${key.value}:responseValues`
    );
    const localOtherValues = localStorage.getItem(`${key.value}:otherValues`);
    const localBookmarks = localStorage.getItem(`${key.value}:bookmarks`);

    if (localResponseValues) {
      store.setResponseValues(JSON.parse(localResponseValues));
    }

    if (localOtherValues) {
      store.setOtherValues(JSON.parse(localOtherValues));
    }

    if (localBookmarks) {
      store.setBookmarks(JSON.parse(localBookmarks));
    }

    let secret = localStorage.getItem("secret");
    if (!secret) {
      secret = window.prompt("secret")!;
      localStorage.setItem("secret", secret);
    }
    generateHS256Token(secret, {
      package: route.params.package,
      iss: "urn:chromex:auth",
      aud: "urn:chromex:qti"
    }).then(t => {
      store.setToken(t);
    });

    return {
      token
    };
  }
});
